import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
const theme = createTheme();

export default function UploadVideo({
  uploadopen,
  setTitle,
  setShares,
  setSharePrice,
  setVideoFile,
  setThumbnailFile,
  handleUploadAction,
  handleUploadClose,
  uploadingProgress,
  title,
  videofile,
  thumbnailfile,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="false">
        <Dialog open={uploadopen} onClose={handleUploadClose}>
          <div style={{ width: 500 }}>
            <DialogContent>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div align="center">
                  <Typography component="h1" variant="h5">
                    Upload Video
                  </Typography>
                </div>
                <Box component="form">
                  <h6>Video Title</h6>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Video Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {/* <TextField
                  inputProps={{ type: "number" }}
                  margin="normal"
                  required
                  fullWidth
                  name="shares"
                  label="No. of shares"
                  type="shares"
                  id="shares"
                  autoComplete="shares"
                  digit
                  onChange={(e) => setShares(e.target.value)}
                />
                <TextField
                  inputProps={{ type: "number" }}
                  margin="normal"
                  required
                  fullWidth
                  name="price"
                  label="Share price"
                  type="shares"
                  id="shares"
                  autoComplete="shares"
                  onChange={(e) => setSharePrice(e.target.value)}
                /> */}
                  <h6>Select Video File</h6>
                  <br />
                  <input
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    hidden
                    style={{ display: "none" }}
                    id="contained-button-file-video"
                    onChange={(e) => {
                      setVideoFile(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="contained-button-file-video">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <br />
                  <br />
                  <br />
                  <h6>Select Thumbnail File</h6>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    style={{ display: "none" }}
                    id="contained-button-file-thumbnail"
                    onChange={(e) => setThumbnailFile(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file-thumbnail">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <br />
                  <br />
                  {uploadingProgress ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleUploadAction}
                      color="success"
                      disabled={title === "" || !videofile || !thumbnailfile}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </Box>
            </DialogContent>
          </div>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
