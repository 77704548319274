import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
const theme = createTheme();

export default function PromoteVideo({
  promoteopen,
  setTitle,
  setVid,
  processVideoFile,
  setThumbnailFile,
  handleBrandUploadAction,
  handlePromoteClose,
  uploadingProgress,
  title,
  vid,
  videofile,
  thumbnailfile,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="false">
        <Dialog open={promoteopen} onClose={handlePromoteClose}>
          <div style={{ width: 500 }}>
            <DialogContent>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div align="center">
                  <Typography component="h1" variant="h5">
                    Promote Video
                  </Typography>
                </div>
                <Box component="form">
                  <h6>Title</h6>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                    autoFocus
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <br />
                  <h6>
                    Video Id (Get the video Id from the{" "}
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.startup.cene"
                    >
                      Cene app
                    </a>
                    )
                  </h6>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="vid"
                    label="VideoId (Get the video Id from the Cene app)"
                    name="vid"
                    autoComplete="vid"
                    autoFocus
                    onChange={(e) => setVid(e.target.value)}
                  />
                  <h6>Select Promotion Video File</h6>
                  <br />
                  <input
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    hidden
                    style={{ display: "none" }}
                    id="contained-button-file-video"
                    onChange={(e) => processVideoFile(e.target.files[0])}
                    required
                  />
                  <label htmlFor="contained-button-file-video">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <br />
                  <br />
                  <br />
                  <h6>Select Preview Image</h6>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    style={{ display: "none" }}
                    id="contained-button-file-thumbnail"
                    onChange={(e) => setThumbnailFile(e.target.files[0])}
                    required
                  />
                  <label htmlFor="contained-button-file-thumbnail">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <br />
                  <br />
                  {uploadingProgress ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleBrandUploadAction}
                      color="success"
                      disabled={
                        title === "" ||
                        vid === "" ||
                        !videofile ||
                        !thumbnailfile
                      }
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </Box>
            </DialogContent>
          </div>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
