class BrandVideo {
  constructor(id, date, file, previewImage, previewTitle, promoter) {
    this.id = id;
    this.date = date;
    this.file = file;
    this.previewImage = previewImage;
    this.previewTitle = previewTitle;
    this.promoter = promoter;
  }
}
export default BrandVideo;
