class BrandVideoRewad {
  constructor(id, date, file, previewImage, previewTitle) {
    this.id = id;
    this.date = date;
    this.file = file;
    this.previewImage = previewImage;
    this.previewTitle = previewTitle;
  }
}
export default BrandVideoRewad;
