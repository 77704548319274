import React, { useState, useEffect } from "react";
import SignIn from "../Login/Signin";
import UploadVideo from "../UploadPage/UploadVideo";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  app,
  firebaseConfig,
  auth,
  googleProvider,
} from "../../firebase-config";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import Video from "../VideoObject/Video";
import PromoteVideo from "../PromotePage/PromoteVideo";
import BrandVideo from "../BrandVideoObject/BrandVideo";
import { serverTimestamp } from "firebase/firestore";
import BrandVideoRewad from "../BrandVideoObject/BrandVideoRewad";
export const Header = (props) => {
  const [open, setOpen] = React.useState(false);
  const [uploadopen, setUploadOpen] = React.useState(false);
  const [promoteopen, setPromoteOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("");
  const [vid, setVid] = useState("");
  const [shares, setShares] = useState(0);
  const [sharePrice, setSharePrice] = useState(0);
  const [videofile, setVideoFile] = useState();
  const [thumbnailfile, setThumbnailFile] = useState();
  const [uploadingProgress, setUploadingProgress] = useState(false);
  const [duration, setDuration] = useState(0);
  let navigate = useNavigate();
  const [storage, setStorage] = useState({});

  const handleUploadClickOpen = () => {
    setUploadOpen(true);
  };

  const handleUploadClose = () => {
    setUploadOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePromoteClickOpen = () => {
    setPromoteOpen(true);
  };

  const handlePromoteClose = () => {
    setPromoteOpen(false);
  };

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    setStorage(firebase.storage());
  }, []);

  const handleAction = (id) => {
    const authentication = getAuth();
    if (id === 2) {
      signInWithPopup(auth, googleProvider)
        .then(async (response) => {
          const db = await getFirestore(app);
          const docRef = await doc(
            db,
            "CeneDatabase",
            response.user.email.toString()
          );
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() === false) {
            alert("Please signup on Cene app on play store.");
          } else {
            let authToken = await response._tokenResponse.refreshToken;
            await sessionStorage.setItem("Auth Token", authToken);
            await handleClose();
            await navigate("/", { state: { authToken: authToken } });
          }
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            toast.error("Email Already in Use");
          }
        });
    }

    if (id === 1) {
      signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          let authToken = response._tokenResponse.refreshToken;
          sessionStorage.setItem("Auth Token", authToken);
          handleClose();
          navigate("/", { state: { authToken: authToken } });
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            toast.error("Please check the Password");
          }
          if (error.code === "auth/user-not-found") {
            toast.error("Please check the Email");
          }
        });
    }
  };

  const handleUpload = async (uuid, filename, file) => {
    const path = `/videos/${uuid}/${filename}`;
    const ref = storage.ref(path);
    await ref.put(file);
    const url = await ref.getDownloadURL();
    return url;
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "bmp":
      case "png":
      case "jpeg":
        //etc
        return true;
    }
    return false;
  };

  const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
    }
    return false;
  };

  const handleUploadAction = async () => {
    if (!videofile) {
      alert("Please choose a video file first!");
    } else if (!thumbnailfile) {
      alert("Please choose a thumbnail file first!");
    } else if (parseInt(shares) > 100) {
      alert("Shares can't be greater than 100");
    } else if (parseInt(sharePrice) > 100) {
      alert("Share Price can't be greater than 100");
    } else if (isVideo(videofile["name"]) === false) {
      alert("Please upload a valid video file");
    } else if (isImage(thumbnailfile["name"]) === false) {
      alert("Please upload a valid thumbnail file");
    } else {
      await firebase.auth().onAuthStateChanged(async function (user) {
        setUploadingProgress(true);
        var uuid;
        var user_email;
        if (user) {
          const db = await getFirestore(app);
          user_email = await user.email;
          const docRef = await doc(db, "CeneDatabase", user_email.toString());
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            uuid = await uuidv4();
            const video_url = await handleUpload(
              uuid,
              videofile.name,
              videofile,
              "video"
            );
            const thumbnail_url = await handleUpload(
              uuid,
              thumbnailfile.name,
              thumbnailfile,
              "thumbnail"
            );

            //Upload video details to firestore
            var Rewads1 = {
              "b198cffe-e4f8-417b-b47e-3dacdc6420dd": {
                date: serverTimestamp(),
                file: [
                  {
                    duration: 28,
                    fileTitle: { en: "Cene" },
                    filetype: "video",
                    url: {
                      en: "https://firebasestorage.googleapis.com/v0/b/ctemp-fd260.appspot.com/o/Advertisements%2Fb198cffe-e4f8-417b-b47e-3dacdc6420dd%2FCeneAd2.mp4?alt=media&token=422a4a5f-d615-46c5-bb5d-6590797834d3",
                    },
                  },
                ],
                id: "b198cffe-e4f8-417b-b47e-3dacdc6420dd",
                previewImage:
                  "https://firebasestorage.googleapis.com/v0/b/ctemp-fd260.appspot.com/o/Advertisements%2Fb198cffe-e4f8-417b-b47e-3dacdc6420dd%2Fcenelogo_alpha_white.png?alt=media&token=86684809-d518-41d8-b952-1216c393e8d8",
                previewTitle: { en: "Cene" },
              },
            };

            var Rewads2 = {
              "5d890a79-ca6e-42fe-a0e9-d2a95f0331c7": {
                date: serverTimestamp(),
                file: [
                  {
                    duration: 22,
                    fileTitle: { en: "Cene" },
                    filetype: "video",
                    url: {
                      en: "https://firebasestorage.googleapis.com/v0/b/ctemp-fd260.appspot.com/o/Advertisements%2F5d890a79-ca6e-42fe-a0e9-d2a95f0331c7%2FCeneAd1.mp4?alt=media&token=5d51f1cd-dbb2-44c2-a726-48d6aa1263ec",
                    },
                  },
                ],
                id: "5d890a79-ca6e-42fe-a0e9-d2a95f0331c7",
                previewImage:
                  "https://firebasestorage.googleapis.com/v0/b/ctemp-fd260.appspot.com/o/Advertisements%2F5d890a79-ca6e-42fe-a0e9-d2a95f0331c7%2Fcenelogo_alpha_white.png?alt=media&token=103df336-42d7-4eec-b9aa-89bd49c083b6",
                previewTitle: { en: "Cene" },
              },
            };

            var currRewadsToBeTaken =
              Math.floor(Math.random() * 2) === 0 ? Rewads1 : Rewads2;

            var date = serverTimestamp();

            var vid = await new Video(
              uuid,
              title,
              thumbnail_url,
              video_url,
              parseInt(shares),
              parseInt(sharePrice),
              [],
              0,
              user_email.toString(),
              0,
              0,
              "0.0",
              {},
              currRewadsToBeTaken,
              date
            );
            // update database doc VideoDatabase
            await setDoc(
              doc(db, "VideoDatabase", uuid.toString()),
              Object.assign({}, vid)
            );
            // update database doc CeneDatabase

            var userDoc = docSnap.data();
            var videosUploaded = userDoc["VideosUploaded"];

            var uploaded_video = {
              collection: "VideoDatabase",
              document: uuid.toString(),
            };
            videosUploaded.push(uploaded_video);

            userDoc["VideosUploaded"] = videosUploaded;
            await setDoc(docRef, userDoc).then(() => {
              alert("Video uploaded successfully");
              setUploadingProgress(false);
              setUploadOpen(false);
              setTitle("");
              setVideoFile();
              setThumbnailFile();
            });
          } else {
            // doc.data() will be undefined in this case
            alert("No such user");
            setUploadingProgress(false);
            setUploadOpen(false);
            setTitle("");
            setVideoFile();
            setThumbnailFile();
          }
        } else {
          // No user is signed in.
          //alert("There is no logged in user");
        }
      });
    }
  };

  const handleBrandUpload = async (filename, file, uuid) => {
    const path = `/Advertisements/${uuid}/${filename}`;
    const ref = storage.ref(path);
    await ref.put(file);
    const url = await ref.getDownloadURL();
    return url;
  };

  const handleBrandUploadAction = async () => {
    if (!videofile) {
      alert("Please choose a video file first!");
    } else if (!thumbnailfile) {
      alert("Please choose a thumbnail file first!");
    } else if (Math.floor(duration) > 30) {
      alert("Video duration should not be greater than 30 seconds");
    } else if (isVideo(videofile["name"]) === false) {
      alert("Please upload a valid video file");
    } else if (isImage(thumbnailfile["name"]) === false) {
      alert("Please upload a valid thumbnail file");
    } else {
      await firebase.auth().onAuthStateChanged(async function (user) {
        setUploadingProgress(true);
        var user_email;
        if (user) {
          const db = await getFirestore(app);
          const docRef = await doc(db, "VideoDatabase", vid.toString());
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            var uuid = await uuidv4();
            user_email = await user.email;
            const video_url = await handleBrandUpload(
              videofile.name,
              videofile,
              uuid
            );

            const thumbnail_url = await handleBrandUpload(
              thumbnailfile.name,
              thumbnailfile,
              uuid
            );

            //set all variables of brand video object

            var date = serverTimestamp();

            var fileObj = new Object();
            fileObj["duration"] = Math.floor(duration);
            var fileEnTitle = new Object();
            fileEnTitle["en"] = title;
            fileObj["fileTitle"] = fileEnTitle;
            fileObj["filetype"] = "video";
            var fileEnVideoUrl = new Object();
            fileEnVideoUrl["en"] = video_url;
            fileObj["url"] = fileEnVideoUrl;
            var fileArray = new Array();
            fileArray[0] = fileObj;

            var previewImage = thumbnail_url;

            var previewTitle = new Object();
            previewTitle["en"] = title;

            var id = uuid;

            var brandVideo = await new BrandVideo(
              id,
              date,
              fileArray,
              previewImage,
              previewTitle,
              user.email
            );

            var brandVideoRewad = await new BrandVideoRewad(
              id,
              date,
              fileArray,
              previewImage,
              previewTitle
            );
            var videoDoc = docSnap.data();

            var Rewads = videoDoc["Rewads"];

            Rewads[uuid] = Object.assign({}, brandVideoRewad);
            videoDoc["Rewads"] = Rewads;
            await setDoc(docRef, videoDoc).then(() => {
              // update database doc VideoDatabase
              setDoc(
                doc(db, "Advertisements", uuid.toString()),
                Object.assign({}, brandVideo)
              ).then(() => {
                alert("Promotion Video uploaded successfully");
                setPromoteOpen(false);
                setUploadingProgress(false);
                setTitle("");
                setVid("");
                setVideoFile();
                setThumbnailFile();
              });
            });
          } else {
            alert("Video does not exist");
            setPromoteOpen(false);
            setUploadingProgress(false);
            setTitle("");
            setVid("");
            setVideoFile();
            setThumbnailFile();
          }
        } else {
          // No user is signed in.
          //alert("There is no logged in user");
        }
      });
    }
  };

  const processVideoFile = async (file) => {
    var reader = new FileReader();
    reader.onload = function () {
      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        setDuration(media.duration); // this would give duration of the video/audio file
      };
    };
    await reader.readAsDataURL(file);
    await setVideoFile(file);
  };
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <p style={{ display: "inline-block" }}>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.startup.cene"
                  >
                    <img
                      style={{
                        height: "80px",
                        width: "190px",
                        marginLeft: "10px",
                      }}
                      src="img/playstore4.png"
                      className="img-responsive"
                      alt=""
                    />
                  </a>
                </p>

                <form>
                  {props.authToken ? (
                    <div className="row">
                      <div className="col-md-1 col-md-offset-4">
                        <input
                          type="button"
                          value="Upload"
                          className="btn btn-custom btn-lg page-scroll"
                          onClick={handleUploadClickOpen}
                        />
                        <UploadVideo
                          uploadopen={uploadopen}
                          setTitle={setTitle}
                          setShares={setShares}
                          setSharePrice={setSharePrice}
                          handleUploadAction={handleUploadAction}
                          setVideoFile={setVideoFile}
                          handleUploadClose={handleUploadClose}
                          setThumbnailFile={setThumbnailFile}
                          uploadingProgress={uploadingProgress}
                          title={title}
                          videofile={videofile}
                          thumbnailfile={thumbnailfile}
                        />
                      </div>
                      <div className="col-md-1 col-md-offset-1">
                        <input
                          type="button"
                          value="Promote"
                          className="btn btn-custom btn-lg page-scroll"
                          onClick={handlePromoteClickOpen}
                        />
                        <PromoteVideo
                          promoteopen={promoteopen}
                          setTitle={setTitle}
                          setVid={setVid}
                          handleBrandUploadAction={handleBrandUploadAction}
                          processVideoFile={processVideoFile}
                          handlePromoteClose={handlePromoteClose}
                          setThumbnailFile={setThumbnailFile}
                          uploadingProgress={uploadingProgress}
                          title={title}
                          vid={vid}
                          videofile={videofile}
                          thumbnailfile={thumbnailfile}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="button"
                        value="Login"
                        className="btn btn-custom btn-lg page-scroll"
                        onClick={handleClickOpen}
                      />
                      <SignIn
                        open={open}
                        handleAction={handleAction}
                        setEmail={setEmail}
                        setPassword={setPassword}
                        handleClose={handleClose}
                        email={email}
                        password={password}
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
