class Video {
  constructor(
    uuid,
    title,
    image,
    videolink,
    no_of_shares,
    sharePrice,
    investors,
    sharesSold,
    creatorEmail,
    no_of_likes,
    no_of_views,
    earnings,
    Leaderboard,
    Rewads,
    date
  ) {
    this.uuid = uuid;
    this.title = title;
    this.image = image;
    this.videolink = videolink;
    this.no_of_shares = no_of_shares;
    this.sharePrice = sharePrice;
    this.investors = investors;
    this.sharesSold = sharesSold;
    this.creatorEmail = creatorEmail;
    this.no_of_likes = no_of_likes;
    this.no_of_views = no_of_views;
    this.earnings = earnings;
    this.Leaderboard = Leaderboard;
    this.Rewads = Rewads;
    this.date = date;
  }
}
export default Video;
