import React from "react";

export const Team = (props) => {
  return (
    <div id="cene-team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>We are hiring...</p>
          <p>E-mail us to become the part of India's best content brand</p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-md-3 col-sm-6 cene-team"
                >
                  <div className="thumbnail">
                    {" "}
                    <img
                      src={d.img}
                      alt="cene startup founder"
                      className="cene-team-img"
                    />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
