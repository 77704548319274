import "./App.css";
import Home from "./components/HomePage/Home";
import Upload from "./components/UploadPage/UploadVideo";
import { useState, useEffect } from "react";
import { app } from "./firebase-config";
import SignIn from "./components/Login/Signin";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import PageNotFound from "./components/404Page/PageNotFound";
import MaintenancePage from "./components/Maintenance/MaintenenancePage";
function App() {
  // useEffect(() => {
  //   let authToken = sessionStorage.getItem('Auth Token')

  //   if (authToken) {
  //     navigate('/',{state:{authToken:authToken}});
  //   }

  //   if (!authToken) {
  //     navigate('/login')
  // }
  // }, [])

  return (
    <div className="App">
      <>
        <Routes>
          {/* <Route path="/" element={<MaintenancePage />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </>
    </div>
  );
}

export default App;
